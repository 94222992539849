import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-colors-page',
  templateUrl: './single-colors-page.component.html',
  styleUrls: ['./single-colors-page.component.scss']
})
export class SingleColorsPageComponent implements OnInit {

  
  constructor() { 
    
  }

  baseColorLightness:number = 0;

  ngOnInit() {
    var getStyle = function(element, property) {
      return window.getComputedStyle ? window.getComputedStyle(element, null).getPropertyValue(property) : element.style[property.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); })];
    }

     
  };

  calculateShades(e) {
  if (!e)
      e = window.event;
  var sender = e.srcElement || e.target;

  var myId = sender.id;

  var newShadingColorRgb = sender.style.backgroundColor;
  
  console.log(newShadingColorRgb);

  function rgb2hex(rgb){
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? "#" +
     ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
     ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
     ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
   }

   var newShadingColorHex =  rgb2hex(newShadingColorRgb);

   console.log(newShadingColorHex);

   function hexToHsl(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);

    r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l);
    h = Math.round(360*h);


    console.log(l);

    var LM3 = l - 15;
    var LM2 = l - 10;
    var LM1 = l - 5;
    var LP1 = l + 7.5;
    var LP2 = l + 15;
    var LP3 = l + 22.5;

    var baseColorHsl = 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
    var baseHslMinus3 = 'hsl(' + h + ', ' + s + '%, ' + LM3 + '%)';
    var baseHslMinus2 = 'hsl(' + h + ', ' + s + '%, ' + LM2 + '%)';
    var baseHslMinus1 = 'hsl(' + h + ', ' + s + '%, ' + LM1 + '%)';
    var baseHslPlus1 = 'hsl(' + h + ', ' + s + '%, ' + LP1 + '%)';
    var baseHslPlus2 = 'hsl(' + h + ', ' + s + '%, ' + LP2 + '%)';
    var baseHslPlus3 = 'hsl(' + h + ', ' + s + '%, ' + LP3 + '%)';

    document.getElementById("preview-shades-minus-30").style.backgroundColor = baseHslMinus3;
    document.getElementById("preview-shades-minus-20").style.backgroundColor = baseHslMinus2;
    document.getElementById("preview-shades-minus-10").style.backgroundColor = baseHslMinus1;
    document.getElementById("preview-shades-base-color").style.backgroundColor = baseColorHsl;
    document.getElementById("preview-shades-plus-10").style.backgroundColor = baseHslPlus1;
    document.getElementById("preview-shades-plus-20").style.backgroundColor = baseHslPlus2;
    document.getElementById("preview-shades-plus-30").style.backgroundColor = baseHslPlus3;
    
    var shadePreviews = document.getElementById("shades-wrapper");

    function rgb2hex(rgb){
      rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
      return (rgb && rgb.length === 4) ? "#" +
       ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
       ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
       ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
     }

    shadePreviews.getElementsByClassName("color-hex")[0].textContent = rgb2hex(document.getElementById("preview-shades-minus-30").style.backgroundColor);
    shadePreviews.getElementsByClassName("color-hex")[1].textContent = rgb2hex(document.getElementById("preview-shades-minus-20").style.backgroundColor);
    shadePreviews.getElementsByClassName("color-hex")[2].textContent = rgb2hex(document.getElementById("preview-shades-minus-10").style.backgroundColor);
    shadePreviews.getElementsByClassName("color-hex")[3].textContent = rgb2hex(document.getElementById("preview-shades-base-color").style.backgroundColor);
    shadePreviews.getElementsByClassName("color-hex")[4].textContent = rgb2hex(document.getElementById("preview-shades-plus-10").style.backgroundColor);
    shadePreviews.getElementsByClassName("color-hex")[5].textContent = rgb2hex(document.getElementById("preview-shades-plus-20").style.backgroundColor);
    shadePreviews.getElementsByClassName("color-hex")[6].textContent = rgb2hex(document.getElementById("preview-shades-plus-30").style.backgroundColor);
    
    shadePreviews.getElementsByClassName("color-rgb")[0].textContent = document.getElementById("preview-shades-minus-30").style.backgroundColor;
    shadePreviews.getElementsByClassName("color-rgb")[1].textContent = document.getElementById("preview-shades-minus-20").style.backgroundColor;
    shadePreviews.getElementsByClassName("color-rgb")[2].textContent = document.getElementById("preview-shades-minus-10").style.backgroundColor;
    shadePreviews.getElementsByClassName("color-rgb")[3].textContent = document.getElementById("preview-shades-base-color").style.backgroundColor;
    shadePreviews.getElementsByClassName("color-rgb")[4].textContent = document.getElementById("preview-shades-plus-10").style.backgroundColor;
    shadePreviews.getElementsByClassName("color-rgb")[5].textContent = document.getElementById("preview-shades-plus-20").style.backgroundColor;
    shadePreviews.getElementsByClassName("color-rgb")[6].textContent = document.getElementById("preview-shades-plus-30").style.backgroundColor;
    
    
    
  }

  hexToHsl(newShadingColorHex);


};

  showDetails(_color){ 


    var getBackgroundColorRGB = function(element) {
      return window.getComputedStyle ? window.getComputedStyle(element, null).getPropertyValue("background-color") : element.style.backgroundColor;
    };
    
    function RgbToHex(color) {
      if (color.substr(0, 1) === '#') {
          return color;
      }
      var digits = /(.*?)rgb\((\d+), (\d+), (\d+)\)/.exec(color);
      
      var red = parseInt(digits[2]);
      var green = parseInt(digits[3]);
      var blue = parseInt(digits[4]);
      
      var rgb = blue | (green << 8) | (red << 16);
      return digits[1] + '#' + rgb.toString(16).padStart(6, '0');
    };

    function changeHue(rgb, degree) {
      var hsl = rgbToHSL(rgb);
      hsl.h += degree;
      if (hsl.h > 360) {
          hsl.h -= 360;
      }
      else if (hsl.h < 0) {
          hsl.h += 360;
      }
      return hslToRGB(hsl);
  };

  // exepcts a string and returns an object
  function rgbToHSL(rgb) {
      // strip the leading # if it's there
      rgb = rgb.replace(/^\s*#|\s*$/g, '');
  
      // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
      if(rgb.length == 3){
          rgb = rgb.replace(/(.)/g, '$1$1');
      }
  
      var r = parseInt(rgb.substr(0, 2), 16) / 255,
          g = parseInt(rgb.substr(2, 2), 16) / 255,
          b = parseInt(rgb.substr(4, 2), 16) / 255,
          cMax = Math.max(r, g, b),
          cMin = Math.min(r, g, b),
          delta = cMax - cMin,
          l = (cMax + cMin) / 2,
          h = 0,
          s = 0;
  
      if (delta == 0) {
          h = 0;
      }
      else if (cMax == r) {
          h = 60 * (((g - b) / delta) % 6);
      }
      else if (cMax == g) {
          h = 60 * (((b - r) / delta) + 2);
      }
      else {
          h = 60 * (((r - g) / delta) + 4);
      }
  
      if (delta == 0) {
          s = 0;
      }
      else {
          s = (delta/(1-Math.abs(2*l - 1)))
      }
  
      return {
          h: h,
          s: s,
          l: l
      }
  }
  
  // expects an object and returns a string
  function hslToRGB(hsl) {
      var h = hsl.h,
          s = hsl.s,
          l = hsl.l,
          c = (1 - Math.abs(2*l - 1)) * s,
          x = c * ( 1 - Math.abs((h / 60 ) % 2 - 1 )),
          m = l - c/ 2,
          r, g, b;
  
      if (h < 60) {
          r = c;
          g = x;
          b = 0;
      }
      else if (h < 120) {
          r = x;
          g = c;
          b = 0;
      }
      else if (h < 180) {
          r = 0;
          g = c;
          b = x;
      }
      else if (h < 240) {
          r = 0;
          g = x;
          b = c;
      }
      else if (h < 300) {
          r = x;
          g = 0;
          b = c;
      }
      else {
          r = c;
          g = 0;
          b = x;
      }
  
      r = normalize_rgb_value(r, m);
      g = normalize_rgb_value(g, m);
      b = normalize_rgb_value(b, m);
  
      return rgbToHex(r,g,b);
  }
  
  function normalize_rgb_value(color, m) {
      color = Math.floor((color + m) * 255);
      if (color < 0) {
          color = 0;
      }
      return color;
  }
  
  function rgbToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

    document.getElementById("details-modal").classList.toggle("visible");
    document.getElementById("details-base-color").style.backgroundColor = _color;
    console.log(_color);

    var baseColor = document.getElementById("details-base-color");

  var baseColorRgb = getBackgroundColorRGB(baseColor);
  var baseColorHex = RgbToHex(baseColorRgb);
  
  var baseColorBox = document.getElementById("base-color");
    
    var colorHexText = baseColorBox.getElementsByClassName("color-hex")[0];
      colorHexText.textContent = baseColorHex;
  
    var colorRgbText = baseColorBox.getElementsByClassName("color-rgb")[0];
      colorRgbText.textContent = baseColorRgb;

    var complementaryColorHex = changeHue(baseColorHex, 180);
    var triadSecondColorHex   = changeHue(baseColorHex, 120);
    var triadThirdColorHex    = changeHue(baseColorHex, -120);
    var squareSecondColorHex  = changeHue(baseColorHex, 90);
    var squareThirdColorHex   = changeHue(baseColorHex, 180);
    var squareFourthColorHex  = changeHue(baseColorHex, 270);

    var colorDetails = document.getElementById("color-details");

    var complementColorHexText = colorDetails.getElementsByClassName("color-hex")[1];
        complementColorHexText.textContent = complementaryColorHex;
    var triadSecondColorHexText = colorDetails.getElementsByClassName("color-hex")[3];
        triadSecondColorHexText.textContent = triadSecondColorHex;
    var triadThirdColorHexText = colorDetails.getElementsByClassName("color-hex")[4];
        triadThirdColorHexText.textContent = triadThirdColorHex;
    var squareSecondColorHexText = colorDetails.getElementsByClassName("color-hex")[6];
        squareSecondColorHexText.textContent = squareSecondColorHex;
    var squareThirdColorHexText = colorDetails.getElementsByClassName("color-hex")[7];
        squareThirdColorHexText.textContent = squareThirdColorHex;
    var squareFourthColorHexText = colorDetails.getElementsByClassName("color-hex")[8];
        squareFourthColorHexText.textContent = squareFourthColorHex;
    
        var complementaryColorPreview = document.getElementById("preview-complement-color");
        var triadSecondColorPreview   = document.getElementById("preview-triad-second-color");
        var triadThirdColorPreview    = document.getElementById("preview-triad-third-color");
        var squareSecondColorPreview  = document.getElementById("preview-square-second-color");
        var squareThirdColorPreview   = document.getElementById("preview-square-third-color");
        var squareFourthColorPreview  = document.getElementById("preview-square-fourth-color");
    
        complementaryColorPreview.style.backgroundColor = complementaryColorHex;
        triadSecondColorPreview.style.backgroundColor = triadSecondColorHex;
        triadThirdColorPreview.style.backgroundColor = triadThirdColorHex;
        squareSecondColorPreview.style.backgroundColor = squareSecondColorHex;
        squareThirdColorPreview.style.backgroundColor = squareThirdColorHex;
        squareFourthColorPreview.style.backgroundColor = squareFourthColorHex;
    
    var complementaryColorRgb = getBackgroundColorRGB(complementaryColorPreview);
    var triadSecondColorRgb = getBackgroundColorRGB(triadSecondColorPreview);
    var triadThirdColorRgb = getBackgroundColorRGB(triadThirdColorPreview);
    var squareSecondColorRgb = getBackgroundColorRGB(squareSecondColorPreview);
    var squareThirdColorRgb = getBackgroundColorRGB(squareThirdColorPreview);
    var squareFourthColorRgb = getBackgroundColorRGB(squareFourthColorPreview);

    var complementColorRgbText = colorDetails.getElementsByClassName("color-rgb")[1];
        complementColorRgbText.textContent = complementaryColorRgb;
    var triadSecondColorRgbText = colorDetails.getElementsByClassName("color-rgb")[3];
        triadSecondColorRgbText.textContent = triadSecondColorRgb;
    var triadThirdColorRgbText = colorDetails.getElementsByClassName("color-rgb")[4];
        triadThirdColorRgbText.textContent = triadThirdColorRgb;
    var squareSecondColorRgbText = colorDetails.getElementsByClassName("color-rgb")[6];
        squareSecondColorRgbText.textContent = squareSecondColorRgb;
    var squareThirdColorRgbText = colorDetails.getElementsByClassName("color-rgb")[7];
        squareThirdColorRgbText.textContent = squareThirdColorRgb;
    var squareFourthColorRgbText = colorDetails.getElementsByClassName("color-rgb")[8];
        squareFourthColorRgbText.textContent = squareFourthColorRgb;

        document.getElementById("preview-base-color-2").style.backgroundColor = baseColorHex;
        document.getElementById("preview-base-color-3").style.backgroundColor = baseColorHex;
        colorDetails.getElementsByClassName("color-hex")[2].textContent = baseColorHex;
        colorDetails.getElementsByClassName("color-hex")[5].textContent = baseColorHex;
        colorDetails.getElementsByClassName("color-rgb")[2].textContent = baseColorRgb;
        colorDetails.getElementsByClassName("color-rgb")[5].textContent = baseColorRgb;


// SHADING 

    

        function hexToHsl(hex) {
          var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      
          var r = parseInt(result[1], 16);
          var g = parseInt(result[2], 16);
          var b = parseInt(result[3], 16);
      
          r /= 255, g /= 255, b /= 255;
          var max = Math.max(r, g, b), min = Math.min(r, g, b);
          var h, s, l = (max + min) / 2;
      
          if(max == min){
              h = s = 0; // achromatic
          } else {
              var d = max - min;
              s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
              switch(max) {
                  case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                  case g: h = (b - r) / d + 2; break;
                  case b: h = (r - g) / d + 4; break;
              }
              h /= 6;
          }
      
          s = s*100;
          s = Math.round(s);
          l = l*100;
          l = Math.round(l);
          h = Math.round(360*h);


          console.log(l);

          var LM3 = l - 15;
          var LM2 = l - 10;
          var LM1 = l - 5;
          var LP1 = l + 7.5;
          var LP2 = l + 15;
          var LP3 = l + 22.5;

          var baseColorHsl = 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
          var baseHslMinus3 = 'hsl(' + h + ', ' + s + '%, ' + LM3 + '%)';
          var baseHslMinus2 = 'hsl(' + h + ', ' + s + '%, ' + LM2 + '%)';
          var baseHslMinus1 = 'hsl(' + h + ', ' + s + '%, ' + LM1 + '%)';
          var baseHslPlus1 = 'hsl(' + h + ', ' + s + '%, ' + LP1 + '%)';
          var baseHslPlus2 = 'hsl(' + h + ', ' + s + '%, ' + LP2 + '%)';
          var baseHslPlus3 = 'hsl(' + h + ', ' + s + '%, ' + LP3 + '%)';

          document.getElementById("preview-shades-minus-30").style.backgroundColor = baseHslMinus3;
          document.getElementById("preview-shades-minus-20").style.backgroundColor = baseHslMinus2;
          document.getElementById("preview-shades-minus-10").style.backgroundColor = baseHslMinus1;
          document.getElementById("preview-shades-base-color").style.backgroundColor = baseColorHsl;
          document.getElementById("preview-shades-plus-10").style.backgroundColor = baseHslPlus1;
          document.getElementById("preview-shades-plus-20").style.backgroundColor = baseHslPlus2;
          document.getElementById("preview-shades-plus-30").style.backgroundColor = baseHslPlus3;
          
          var shadePreviews = document.getElementById("shades-wrapper");

          function rgb2hex(rgb){
            rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
            return (rgb && rgb.length === 4) ? "#" +
             ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
             ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
             ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
           }

          shadePreviews.getElementsByClassName("color-hex")[0].textContent = rgb2hex(document.getElementById("preview-shades-minus-30").style.backgroundColor);
          shadePreviews.getElementsByClassName("color-hex")[1].textContent = rgb2hex(document.getElementById("preview-shades-minus-20").style.backgroundColor);
          shadePreviews.getElementsByClassName("color-hex")[2].textContent = rgb2hex(document.getElementById("preview-shades-minus-10").style.backgroundColor);
          shadePreviews.getElementsByClassName("color-hex")[3].textContent = rgb2hex(document.getElementById("preview-shades-base-color").style.backgroundColor);
          shadePreviews.getElementsByClassName("color-hex")[4].textContent = rgb2hex(document.getElementById("preview-shades-plus-10").style.backgroundColor);
          shadePreviews.getElementsByClassName("color-hex")[5].textContent = rgb2hex(document.getElementById("preview-shades-plus-20").style.backgroundColor);
          shadePreviews.getElementsByClassName("color-hex")[6].textContent = rgb2hex(document.getElementById("preview-shades-plus-30").style.backgroundColor);
          
          shadePreviews.getElementsByClassName("color-rgb")[0].textContent = document.getElementById("preview-shades-minus-30").style.backgroundColor;
          shadePreviews.getElementsByClassName("color-rgb")[1].textContent = document.getElementById("preview-shades-minus-20").style.backgroundColor;
          shadePreviews.getElementsByClassName("color-rgb")[2].textContent = document.getElementById("preview-shades-minus-10").style.backgroundColor;
          shadePreviews.getElementsByClassName("color-rgb")[3].textContent = document.getElementById("preview-shades-base-color").style.backgroundColor;
          shadePreviews.getElementsByClassName("color-rgb")[4].textContent = document.getElementById("preview-shades-plus-10").style.backgroundColor;
          shadePreviews.getElementsByClassName("color-rgb")[5].textContent = document.getElementById("preview-shades-plus-20").style.backgroundColor;
          shadePreviews.getElementsByClassName("color-rgb")[6].textContent = document.getElementById("preview-shades-plus-30").style.backgroundColor;
          
        }

        hexToHsl(baseColorHex);
  };

  
  ColorStart:string = "#00FF00";
  ColorComplement:string;
  ColorTriadFirst:string = this.ColorStart;
  ColorTriadSecond:string;
  ColorTriadThird:string;

}