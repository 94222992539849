import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gradients-page',
  templateUrl: './gradients-page.component.html',
  styleUrls: ['./gradients-page.component.scss']
})
export class GradientsPageComponent implements OnInit {

  constructor() { 
    
  }

   GradientColorStart:string = '#FF0000';
   GradientColorEnd:string = '#00FF00';
   Gradient:string = "";
   deg = 180;

  ngOnInit() {
  }

  showDetails(_start, _end){ 
    this.deg = 180;
    this.GradientColorStart = _start;
    this.GradientColorEnd = _end;
    this.Gradient = "linear-gradient(180deg,"+ _start +"," + _end +")";
    document.getElementById("details-modal").classList.toggle("visible");
    document.getElementById("details-preview").style.backgroundImage=this.Gradient;
    document.getElementById("gradient-preview-start-color").style.backgroundColor=this.GradientColorStart;
    document.getElementById("gradient-preview-end-color").style.backgroundColor=this.GradientColorEnd;
  }

  rotateGradient() {
    
    if (this.deg < 360) 
    {
      console.log(this.deg);
      this.deg = this.deg + 45;
      var rotatedGradient = "linear-gradient("+ this.deg.toString() + "deg," + this.GradientColorStart + "," + this.GradientColorEnd +")";
      console.log(this.deg);
    }
    else 
    {
      this.deg = 45;
      var rotatedGradient = "linear-gradient("+ this.deg.toString() + "deg," + this.GradientColorStart + "," + this.GradientColorEnd +")";
      console.log(this.deg);
    }
    
    document.getElementById("details-preview").style.backgroundImage=rotatedGradient;
  }

}
