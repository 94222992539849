import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SingleColorsPageComponent } from './pages/single-colors-page/single-colors-page.component';
import { ColorPalettesPageComponent } from './pages/color-palettes-page/color-palettes-page.component';
import { GradientsPageComponent } from './pages/gradients-page/gradients-page.component';
import { ColorSchemesPageComponent } from './pages/color-schemes-page/color-schemes-page.component';

const routes: Routes = [
  { path: 'single-colors-page', component: SingleColorsPageComponent },
  { path: 'color-palettes-page', component: ColorPalettesPageComponent },
  { path: 'gradients-page', component: GradientsPageComponent },
  { path: 'color-schemes-page', component: ColorSchemesPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
