import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SingleColorsPageComponent } from './pages/single-colors-page/single-colors-page.component';
import { GradientsPageComponent } from './pages/gradients-page/gradients-page.component';
import { ColorPalettesPageComponent } from './pages/color-palettes-page/color-palettes-page.component';
import { ColorSchemesPageComponent } from './pages/color-schemes-page/color-schemes-page.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SingleColorsPageComponent,
    GradientsPageComponent,
    ColorPalettesPageComponent,
    ColorSchemesPageComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
